import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'
import { GlobalPageContext } from '../types'
import { LegalQuery } from '../../graphql-types'
import createPageContainerProps from '../createPageContainerProps'
import PageContainer from '../components/PageContainer'
import Legal from '../components/Legal'

type Props = PageProps<LegalQuery, GlobalPageContext>

const LegalPage: FC<Props> = ({ data, pageContext }) => {
  const { seoTranslations, commonTranslations, legalTranslations, contactInfo } = data
  const t = { ...seoTranslations!.json!, ...commonTranslations!.json!, ...legalTranslations!.json! }

  return (
    <PageContainer
      translations={t}
      {...createPageContainerProps(data, pageContext)}
    >
      <Legal
        contact={contactInfo?.siteMetadata?.contact}
      />
    </PageContainer>
  )
}

export default LegalPage

export const pageQuery = graphql`
  query Legal($pathRegex: String!, $lang: String!, $pageId: String!) {
    ...PageData
    ...ContactInfo

    legalTranslations: file(
      name: { regex: $pathRegex }
      extension: { eq: "json" }
      absolutePath: { regex: "/translations/" }
    ) {
      json: childTranslationsJson {
        labels {
          executives
          phone
          address
          email
          euVAT
          regNum
        }
      }
    }
  }
`
