import React, { FC } from 'react'
import { Text, Box } from 'rebass'

import useIntl from '../states/useIntl'
import PageHeading from './PageHeading'
import ContainerFixed from '../ui/ContainerFixed'
import { ContactInfoFragment } from '../../graphql-types'

type Props = NonNullable<Required<ContactInfoFragment['contactInfo']>>['siteMetadata']

const Legal: FC<Props> = ({ contact }) => {
  const { t } = useIntl()

  return (
    <ContainerFixed minHeight="80vh">
      <PageHeading my={4}>
        {t('meta.title')}
      </PageHeading>

      <Text
        as="h2"
        mb={4}
        fontSize={22}
      >
        ORMO Digital Development Kft.
      </Text>

      <Box
        as="ul"
        mb={5}
      >
        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.executives')}: Zsanett Janik
        </Text>

        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.phone')}: {contact?.display?.phone}
        </Text>

        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.address')}: 1066 Budapest, Teréz krt. 20., II/1.
        </Text>

        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.email')}: {contact?.display?.email}
        </Text>

        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.euVAT')}: HU23691139
        </Text>

        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.regNum')}: 01-09-283725
        </Text>
      </Box>

      <Text
        as="h2"
        mb={4}
        fontSize={22}
      >
        ORMO Digital Delivery Kft.
      </Text>

      <Box
        as="ul"
        mb={5}
      >
        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.executives')}: Gábor Janik
        </Text>

        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.address')}: 2040 Budaörs, Gyár utca 2.
        </Text>

        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.email')}: delivery@ormo.hu
        </Text>

        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.euVAT')}: HU27792452
        </Text>

        <Text
          as="li"
          mb={3}
          color="text"
          fontSize={18}
        >
          {t('labels.regNum')}: 13-09-219123
        </Text>
      </Box>
    </ContainerFixed>
  )
}

export default Legal
